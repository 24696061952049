/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Layout, Main } from '../AdminTeamCreation/styles';
import { TabsWrapper, TabsButton, CreateChallengeButton } from './styles';
import ActivityCategory from './activityCategories';
import BehaviorCategory from './behaviorCategories';
import {TabContainer, TabView} from '../ChallengeDetailsV2/styles';
import { CreateChallengeIcon } from '../../utils/icons';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { fetchChallengeTypeCount, fetWellbeingCategories } from '../../redux/actions';
import _ from 'lodash';
import Waiting from '../Waiting';

class ChallengesCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: 1,
      selectedTab: this.props.history.location && this.props.history.location.query && this.props.history.location.query.isCategory ? 2 : 1,
      hoverButton: false
    }
  }

  componentDidMount() {
    this.props.fetchChallengeTypeCount();
  }

  redirection = () => {
    const { history } = this.props;
    history.push('/company/challenges/create-activty')
  };

  showPastEvents = (path) => {
    this.props.history.push(path);
  };

  onCloseTab = (value) => {
    this.setState({
      selectedTab: value
    })
    if(value===2){
      this.props.fetWellbeingCategories()
    }
  };

  onHover = (value) => {
    this.setState({
      hoverButton: value
    })
  };

  render() {
    const { history, activityChallengeType, wellbeingCategoriesData, role, t } = this.props;
    const { selectedTab, hoverButton } = this.state;
    if (_.isNull(activityChallengeType)|| _.isUndefined(activityChallengeType)) {
      return <Waiting />
    }
    return (
      <Layout>
        <Main>
          {
            <TabContainer marginBottom="25px">
              <TabView  width padding active={false}  onClick={() => this.showPastEvents(history.location.pathname.includes("/company/challenges/")?'/company/challenges':'/challenges')}>
                {history.location.pathname.includes("/company/challenges/")?t("Overview"):t("Dashboard")}
              </TabView >
              <TabView width padding active={false} onClick={() => this.showPastEvents(history.location.pathname.includes("/company/challenges/")?'/company/challenges/completed':'/challenges/completed')}>
                {" "}
                {t("Completed")}
              </TabView >
              <TabView 
                width
                padding
                active={true}
              >
                {" "}
                {t("Library")}
              </TabView >
            </TabContainer>
          }
          <TabsWrapper>
            <TabsButton font={selectedTab===1?"Rubik-Medium":"Rubik"} border={selectedTab===1?"none":"1px solid #005C8799"} color= {selectedTab===1?"white":"#005C8799"} background={selectedTab===1?"#005C87":"white"} height={"40px"} width={"110px"} onClick={() => this.onCloseTab(1)}>
              {t("Activity")}
            </TabsButton>
            <TabsButton font={selectedTab===2?"Rubik-Medium":"Rubik"} border={selectedTab===2?"none":"1px solid #005C8799"} color= {selectedTab===2?"white":"#005C8799"} background={selectedTab===2?"#005C87":"white"} height={"40px"} width={"110px"} margin="0 0 0 15px" onClick={() => this.onCloseTab(2)}>
              {t("Wellness")}
            </TabsButton>
            {role=="ADMIN"&&<CreateChallengeButton active={hoverButton==true} onMouseEnter={() => this.onHover(true)}  onMouseLeave={() => this.onHover(false)} onClick={selectedTab===1?history.location.pathname.includes("/company/challenges/categories")?() => history.push('/company/challenges/create-activty'):() => history.push('/challenges/create-activty'):history.location.pathname.includes("/company/challenges/categories")?() => history.push('/company/challenges/create-behavior'):() => history.push('/challenges/create-behavior') }>
              <div className='image'>
                <CreateChallengeIcon/>
              </div>
              <div className='text'>
                {t("Create Challenge")}
              </div>
            </CreateChallengeButton>}
          </TabsWrapper>
          {selectedTab===1?<ActivityCategory history={history} activityChallengeType={activityChallengeType} role={role}/>:
            <BehaviorCategory history={history} wellbeingCategoriesData={wellbeingCategoriesData.slice(0, 6)} role={role}/>}
        </Main>
      </Layout>
    );
  }
}

ChallengesCategory.propTypes = {
  history: PropTypes.object,
  role: PropTypes.string,
  fetchChallengeTypeCount: PropTypes.func,
  activityChallengeType: PropTypes.array,
  fetWellbeingCategories: PropTypes.func,
  wellbeingCategoriesData: PropTypes.array,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  activityChallengeType: state.challenges.activityChallengeType,
  wellbeingCategoriesData: state.adminReports.wellbeingCategoriesData
})

const mapDispatchToProps = (dispatch) => ({
  fetchChallengeTypeCount: () => dispatch(fetchChallengeTypeCount()),
  fetWellbeingCategories: () => dispatch(fetWellbeingCategories())
})  

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChallengesCategory));
