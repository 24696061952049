import styled, { css } from 'styled-components';

const ChallengeHeading = styled.div`
display: flex;
width: 100%;
background: #69C2FF;
border-radius:6px;
font-family: Rubik-Medium;
font-size: 18px;
line-height: 24px;
color:white;
height:70px;
padding-left:30px;
align-items: center;
margin-bottom: 25px;
`;

const Container = styled.div`
display: flex;
width: 100%;
justify-content: space-between;
.stepsNameHighlight{
  width: 32.1%; 
  display: flex;
  max-width:400px;
  height:100%;
  border-radius:6px;
}
.form{
  width: calc(67.9% - 25px);
  margin-left: 25px;
  display: block;
  background: ${({ background}) => background ? "none" : 'white'};
  border-radius:6px;
  .heading{
    width:100%;
    border-bottom: ${({ disable }) => disable ? "none" : '1px solid rgba(156, 156, 156, 0.4)'};
    display: flex;
    height: 70px;
    background: white;
    border-radius: 6px 6px 0 0;
    .step{
      width:40px;
      height:40px;
      background:#69c2ff;
      display:flex;
      align-items:center;
      justify-content:center;

      font-family: Rubik-Medium;
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      color:white;
      margin: 15px;
    }
    .headingName{
      font-family: Rubik-Medium;
      font-size: 18px;
      line-height: 24px;
      color:#002f47;
      display:flex;
      align-items:center;
    }
  }
  .formBody{
    width:100%;
    display:block;
    padding: 10px 50px 50px 50px;
    background:white;
    .dateCard{
      width:100%;
      display:flex;
      justify-content: space-between;
      margin-top: 10px;
      .separate{
        font-family: "Rubik-Medium";
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        display:flex;
        align-items:center;
        padding-top: 40px;
      }
    }
    .checkbox{
      width:100%;
      display:flex;
      .text{
        display:flex;
        margin:auto;
        margin-right:0px;
        align-items:center;
        font-family: Rubik;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color:#9c9c9c;
      }
    }
    .radioButtonDiv{
      width:100%;
      display:flex;
      justify-content:flex-start;
      align-items:center;flex-direction:row;
      span{  width:auto;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #002F47;
        display:flex;
        justify-content:flex-start;
        align-items:center;
        flex-direction:row;
        margin-right: 50px;
      }
    }
  }
  .taskHeading{
    width:100%;
    display:flex;
    border-bottom: 1px solid rgba(156, 156, 156, 0.4);
    background:white;
    .task{
      font-family: Rubik-Medium;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color:#002f47;
      diplay:flex;
      margin:auto;
      margin-left:25px;
      padding: 15px 0px;
    }
    .imageWrapper{
      display:flex;
      margin:auto;
      margin-right:15px;
      >img{
        width:30px;
        height:30px;
        margin-left:10px;
      }
    }
  }
}
`;

const StepsWrapper = styled.div`
display: block;
width: 100%;
padding: 50px 40px;
background:white;
border-radius: 6px;
`;

const Steps = styled.div`
display: flex;
width: 100%;
flex-wrap:wrap;
.wrapper{
    width:100%;
    display:flex;
.number{
  width:40px;
  height:40px;
  background: ${({ active }) => active ? "#69C2FF" : 'rgba(156, 156, 156, 0.1)'};
  margin-right: 25px; 

  font-family: ${({ active }) => active ? "Rubik-Medium" : 'Rubik'};
  font-size: 24px;
  line-height: 24px;
  color: ${({ active }) => active ? "white" : '#9c9c9c'};
  align-items: center;
  display: flex;
  justify-content: center;
}
.heading{
    font-family: ${({ active }) => active ? "Rubik-Medium" : 'Rubik'};
    font-size: 18px;
    line-height: 24px;
    color: ${({ active }) => active ? "#002f47" : '#002f47'};
    align-items: center;
    display: flex;
}
}
.arrow{
    width:100%;
    display:flex;
    .active{
        width: 40px;
        height:40px;
        display:flex;
        justify-content:center;
        margin:15px 0px;
        >img{
            width: 30px;
            height: auto;
            transform: rotate(90deg);
        }
    }
}
`;

const InputContainer = styled.div`
  width: ${({ width }) => width ? width : '100%'};
  display: flex;
  justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  margin: ${({ margin }) => margin ? 'none' : 'auto'};
  padding-bottom: 25px;
  padding: ${({ padding }) => padding ? padding : '0'};
  .PhoneInput{
    margin-left: -35px;
      .PhoneInputInput{
        padding: 12px;
      }
  }
  .dropdown{
  >option {
  
  .open{
  &::placeholder {
    font-size: 18px;
    color: #9c9c9c;
    font-family: rubik;
    font-weight: 400;
    vertical-align: middle;
   
  } } @media (max-width: 1200px) {
    width: 100%;
  }
  }}

  @media (max-width: 600px) {
    width: 100%;
    
  }


  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledLabel = styled.label`
  float: left;
  display: block;
  font-size: 16px;
  color: rgba(0, 47, 71, 1);
  line-height: 21px;
  font-family: Rubik;
  font-weight: 400;
  position: relative;
  pointer-events: none;
  margin-top:25px;
  margin-bottom:15px;
  display: flex;
  width: 100%;
  >span{
    font-size: 18px;
    color:#F6797D;
  }
  .charCount{
    display:flex;
    margin:auto;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #9c9c9c;
    margin-right:0px;

  }
`;

const StyledInputV2 = styled.input`
  border: none;
  padding: 0px 20px 0px 20px
  box-shadow: none;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
  height: 60px;
  font-family: rubik-medium;
  font-weight:500;
  width: 100%;
  border-radius:6px;
  color: rgba(0, 47, 71, 1);
  border: 1px solid #cdcbcb;
  background-color: rgba(156, 156, 156, .04);

  &:active{
    border: 1px solid #9c9c9c;
  }
  &::placeholder {
    font-size: 16px;
    color: #9c9c9c;
    font-family: rubik;
    font-weight: 400;
    vertical-align: middle;
    line-height: 20px;
  }

  &:focus, &:active {
    outline: none;
  }
  
`;

const UploadPhotoContainer = styled.div`
width:100%
height:250px;
display: flex;
  justify-content: flex-start;
  align-items:center;
  margin-top:15px;
   .innerDivImageContainer{
    width:100%;
    height:100%;
   position:relative;
   display: flex;
  justify-content: flex-start;
  align-items:center;
   }

   .innerDivImageContainer img{
  width:auto;
  height:250px;
  object-fit:cover;
border-radius:3px;
border:1px dashed #9C9C9C
>img{
  width:100%;
  height:100%;
}

}

.innerDivImageContainer  .infoBtn{
width:24px;
heigth:24px;
font-size:15px;
color:#fff;
border-radius:50%;
background-color:#9C9C9C}

@media (max-width: 600px) {
  width:100%;
   flex-direction: column;
   justify-content:center;
   .innerDivImageContainer {
    width:125px;
  height:125px;
  border-radius:50%;
  overflow:hidden;
   }
   .innerDivImageContainer  .infoBtn{display:none}
   .innerDivImageContainer  img{
  width:125px;
  height:125px;
  border-radius:50%
}
}
`

const ImageInput = styled.label`
  color: ${({ Color }) => Color ? Color : 'white'};
  padding: 9px 20px;
  background-color:${({ bgColor }) => bgColor ? bgColor : '#159fc9'};
  border-radius: 30px;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  line-height: ${({ lineHeight }) => lineHeight && '1.428571429'};
  margin-top: 10px;
  margin-top: ${({ margin }) => margin ? margin : '10px'};
  font-family: "Rubik-Medium";
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  
  > input {
    opacity: 0;
    z-index: -1;
    position: absolute;
    bottom: ${({ imageReload }) => imageReload && '0'};
  }
  
  ${(props) => props.showAtBottom && css`
    position: absolute;
    left: calc(40% + 20px);
    bottom: 0;

    @media (max-width: 500px) {
      left: 0;
      position: static;
    }
  `}
`;

const InputImage = styled(ImageInput)`
display: flex;
justify-content: center;
align-items:center;
width:164px;
height:50px;
background-color:#69C2FF;
font-size:18px;
margin:0 25px;
border-radius:3px;
cursor:pointer
  padding: 0;
  margin-bottom: 0;
  > i {
font-family:rubik-medium;
font-style:normal;
font-weight:400;
font-size:18px;
color:#fff; 
text-transform: capitalize;
  }
  @media (max-width: 600px) {
    position: absolute;
background-color:#c4c4c4;
margin:0;
    height: 40px;
    width: 125px;
    top: 85px;
    border-radius: 0 0 175px 175px;
    font-size: 10px;
    >i{
      font-size:10px;
    }
  }
`;

const RemoveImage = styled.div`
display: flex;
justify-content: center;
color:white;
align-items:center;
width:164px;
height:50px;
background-color:#FD7175;
font-size:18px;
margin:0 25px;
border-radius:3px;
cursor:pointer
padding: 0;
margin-bottom: 0;
font-family:rubik-medium;
@media (max-width: 600px) {
  position: absolute;
background-color:#c4c4c4;

margin:0;
    height: 40px;
    width: 125px;
    top: 85px;
    border-radius: 0 0 175px 175px;
  font-size: 10px;
  >i{
    font-size:10px;
  }
}
`;

const CustomTextArea = styled.textarea`
  font-size: 16px;
  line-height: 20px;
  font-family: "Rubik-Medium";
  padding: ${({ padding }) => padding ? '0 15px' : '15px'};
  border-radius: 4px;
  border: 1px solid rgba(156, 156, 156, 1);
  width: 100%;
  resize: none;
  color: #002f47;
  cursor: ${({ disableArea }) => disableArea && 'not-allowed'};
  height: ${({ height }) => height ? height : '165px'};
  background: rgba(156, 156, 156, 0.05);
  &:focus, &:active {
    outline: none;
  }

  ::placeholder {
    color: #9c9c9c;
    opacity: 1;
    font-family: "Rubik";
  }

  :-ms-input-placeholder {
    color: #9c9c9c;
  }

  ::-ms-input-placeholder {
    color: #9c9c9c;
  }
`;

const ButtonContainer = styled.div`
margin-top: 10px;
width: 100%;
background:white;
display: flex;
align-items: center;
text-align: center;
padding:10px 0px;
margin-bottom:100px;
.wrapper{
  width: 100%;
  max-width: 1246px;
  margin: auto;
  display: flex;
}
@media (max-width: 600px) {
  width: calc(100% + 32px);
  margin: 0;
  margin-left: -16px;
  justify-content:center; 
  position:unset;
 flex-direction: column-reverse;
  flex-wrap:wrap;
  padding:25px 0;
  border-top:none;
  box-shadow:unset;
  background: #f3f6f9;
}
`;

const Button = styled.button`
  margin:auto;
  width: 200px;
  width: ${({ width }) => width ? width : '200px'};
  background: ${({ color, disabled }) =>  disabled ? "rgba(156, 156, 156, .6)" :color ? color: '#69c2ff'};
  border: ${({ border, disabled }) => disabled ? "#9C9C9C" :border ? `1px solid ${border}`: '1px solid #69c2ff'};
  float: right;
  padding: 10px;
  font-size: 18px;
  color: ${({ textColor }) =>textColor?textColor:'white'};
  margin-left: ${({ marginLeft, marginAuto }) => marginAuto?"auto":marginLeft ? '0px' : '0px'};
  margin-right: ${({ marginRight }) => marginRight ? '15px' : '0px'};
  border-radius: 3px;
  font-family:"Rubik-Medium";
  &:first-child {
  font-size: 18px;
  }
  
  >img {
    margin-left: 10px;
    font-size: 12px;
    width:10px;
    height:15px;
  }
  
  ${({ color }) => color && css`
    > i {
      margin-left: 0;
      margin-right: 5px;
    }
  `}
  @media (max-width: 600px) {
    width: 90%;
    margin-top:5px;
   background-color:${({ color }) => color && "transparent"}
   border:${({ color }) => color && "none"}
  }

`;

const NextButton = styled(Button)`
  margin: 0px;
  width: 200px;
  height:50px;
  font-family: 'Rubik';
  margin:8px 0;
  margin: auto;
  margin-right: 15px;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
background: #69C2FF;
border-radius: 3px;
display: flex;
align-items: center;
justify-content:center;
text-align: center;
color: #FFFFFF;
  
  >img {
    margin-left: 10px;
    font-size: 12px;
    width:10px;
    height:15px;
  }
  @media (max-width: 600px) {
    width: 90%;
  
  }
`;

const AddTaskButtonWrapper = styled.div`
display: flex;
width: 100%;
flex-wrap:wrap;
padding-bottom:15px;
`;

const AddTaskButton = styled.div`
height: 40px;
min-width: 104px;
border-radius: 3px;
color: ${({ active, addTask }) => active? 'white':addTask?"#69c2ff":'#9c9c9c'};
background: ${({active }) => active? '#69c2ff':'white'};
border: ${({active,addTask }) => active? 'none':addTask?'1px solid #69c2ff':'1px solid #9c9c9c'};
cursor:pointer;
font-size: ${({active }) => active? '16px':'18px'};
font-family: ${({active }) => active? 'Rubik-Medium':'Rubik-Medium'};
display:flex;
align-items:center;
justify-content:center;
margin:15px 15px 0px 0px;
`;

const CustomRadioButton = styled.div`
margin-right:10px;
width:24px;
height:24px;
border:1px solid #9c9c9c
border-radius:50%;
display: flex;
justify-content:center;
align-items:center;
>div{
  width:14px;
  height:14px;
  border-radius:50%;
  background:#fd7175
}
`;

const FieldTitleNote = styled.div`
  font-size: 16px;
  margin-bottom : 25px;
  letter-spacing: 0px;
  line-height: 16.5px
  color: #FD7175;
  font-family: 'Rubik';
  width:100%;
  background: rgba(156, 156, 156, .04);
  background: ${({background }) => background? 'rgba(156, 156, 156, .04)':'none'};
  padding:3px 0px 3px 10px;
  display:flex;
  align-items:center;
  .day {
    font-size: 18px;
    color: #159fc9;
  }
`;

const DropdownArrow = styled.img`
float: right;
top:${({ imageArrow }) => imageArrow ? "110px" : " 105px"}
position: absolute;
right: 15px;
display:block
z-index:5
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ background }) => background ? background : '#69c2ff'};
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
  
  &:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 0px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
`;

const CustomLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 39px;
  height: 20px;
  margin-bottom: 0;

  @media (max-width: 500px) {
    left: calc(100% - 60px);
  }
  
  > input {
    display: none;
  }
  
  > input:checked + .slider {
    background-color: #81d487;
  }

  > input:focus + .slider {
    box-shadow: 0 0 1px #81d487;
  }
  
  > input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }
`;

const EditorContainer = styled.div`
width: 100%;
margin-top: 15px;
`;

export { ChallengeHeading, Container, StepsWrapper, Steps, InputContainer, StyledInputV2, StyledLabel, UploadPhotoContainer, RemoveImage, InputImage, CustomTextArea, ButtonContainer, Button, NextButton, AddTaskButtonWrapper, AddTaskButton, CustomRadioButton, FieldTitleNote, DropdownArrow, CustomLabel, Slider, EditorContainer }